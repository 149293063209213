import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Work = ({ data }) => {
    const cate = data.categories.map((value, idx) => {
        return (
            <span className="d-inline" key={idx}>
                {value}
                {idx !== data.categories.length - 1 && " , "}
            </span>
        );
    });
    return (
        <div className="single-project-slide">
            <div className="thumb">
                <img
                    className="fit-image"
                    src={process.env.PUBLIC_URL + data.media.thumb}
                    alt="Product"
                />
            </div>

            <div className="content">
                <h4 className="subtitle">{cate}</h4>
                <h3 className="title">{data.title}</h3>
            </div>
        </div>
    );
};

Work.propTypes = {
    data: PropTypes.object,
};

export default Work;
