const Footer = () => {
    return (
        <div className="section bg-dark">
            <div className="container">
                <div className="footer-info-box text-center">
                    <h4 className="title">Vragen of bestellen?</h4>
                    <p>
                        <br />
                        Ons aanbod is eindeloos.
                        <br />
                        <br />
                        Ben je op zoek naar een speciaal stuk vlees maar kan je
                        het niet direct vinden in ons assortiment, plaats dan
                        gerust je bestelling via e-mail.
                        <br />
                        Ook voor gewone bestellingen kan je hier steeds terecht.
                        <br />
                        Gelieve je bestelling 2 dagen vooraf voor 10u &apos;s
                        morgens door te geven.  
                    </p>
                    <h2 className="mail">
                        <a href="mailto:info@hoeveslagerijsmismans.be">
                            info@hoeveslagerijsmismans.be
                        </a>
                    </h2>
                </div>

                <div className="footer-copyright-social">
                    <div className="footer-copyright">
                        <p>
                            &copy; <span> Hoeveslagerij Smismans</span> 🐷
                            <span>
                                {" "}
                                ons vakmanschap eet je met verstand.
                            </span>{" "}
                            <br />
                            You are in{" "}
                            <a
                                href="https://greatcompany.be"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Great Company
                            </a>{" "}
                        </p>
                    </div>
                    <div className="footer-social">
                        <ul className="social">
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="Facebook"
                                    href="/"
                                >
                                    Facebook
                                </a>
                            </li>
                            <li>
                                <a
                                    rel="noopener noreferrer"
                                    target="_blank"
                                    className="Instagram"
                                    href="/"
                                >
                                    Instagram
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
