const HomeAbout = () => {
    return (
        <div className="section bg-dark overflow-hidden">
            <div className="about-section section-padding mt-n3 mb-n1">
                <div
                    className="about-images"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/images/about-images.png)`,
                    }}
                ></div>

                <div className="container">
                    <div
                        className="row justify-content-end"
                        data-aos="fade-up"
                        data-aos-duration="1200"
                    >
                        <div className="col-lg-6">
                            <div className="about-content">
                                <h3 className="title">Nick Smismans</h3>
                                <p className="about-text">
                                    De cirkel is rond. Van de Hondzochtse weides
                                    naar Ter Groene Poorte in Brugge en ervaring
                                    op te doen in Brussel, is Nick Smismans nu
                                    klaar om het beste van beide werelden te
                                    combineren in Lembeek.
                                </p>
                                <p>
                                    De passie voor dieren en landbouw wordt al{" "}
                                    <span className="highlight">
                                        {" "}
                                        4 generaties doorgegeven in de familie,
                                    </span>{" "}
                                    dat je Nick van kleins af aan dus tussen de
                                    dieren kon vinden hoeft dus niet te
                                    verbazen.
                                </p>
                                <p>
                                    Aangezien Nick ook meer wou doen dan enkel
                                    dieren kweken was
                                    <span className="highlight">
                                        {" "}
                                        Ter Groene Poorte
                                    </span>{" "}
                                    in Brugge een volgende logische stap.
                                </p>
                                <p>
                                    Na zich nog verder te verdiepen in het vak
                                    bij de beste slagers in België kan je nu in
                                    Lembeek terecht voor
                                    <span className="highlight">
                                        {" "}
                                        vlees van eigen bodem{" "}
                                    </span>
                                    doordrongen van vakkennis en passie.
                                </p>
                                <p>
                                    <span className="highlight">
                                        {" "}
                                        Artisanale en huisbereide producten
                                    </span>{" "}
                                    gemaakt van dieren van{" "}
                                    <span className="highlight">
                                        {" "}
                                        eigen bodem
                                    </span>{" "}
                                    zijn samen met de vakkennis van Nick het
                                    uithangbord van deze hoeveslagerij.
                                </p>
                                <p>
                                    <span className="highlight">
                                        {" "}
                                        Voldoende eigen ruimte
                                    </span>{" "}
                                    voor alle dieren op zowel{" "}
                                    <span className="highlight">
                                        {" "}
                                        onze weilanden
                                    </span>{" "}
                                    als in{" "}
                                    <span className="highlight">
                                        {" "}
                                        onze stallen
                                    </span>{" "}
                                    vinden wij al 4 generaties lang van het
                                    grootste belang. Je kan bij ons terecht voor
                                    een totaal assortiment aan:
                                </p>
                                <ul>
                                    <li>
                                        - Eigen opgebracht rund en lamsvlees
                                    </li>
                                    <li>
                                        - Varken- en kippenvlees van korte keten
                                    </li>
                                    <li>
                                        - Huisbereide klaargemaakte panklare
                                        gerechten.
                                    </li>
                                    <li>
                                        - Huisbereide, artisanale charcuterie
                                    </li>
                                    <li>
                                        - Salades, kazen, soepen en enkele zoete
                                        lekkernijen
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeAbout;
